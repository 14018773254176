<template>
    <div class="table-tools">
        <div 
            v-if="toolsRight"
            class="relative flex ml-auto align-right tools items-center" 
            :class="{'flex__justify-between': $slots.panel, 'w__100-p': $slots.panel}"
        >
            <div class="flex">
                <slot v-if="$slots.panel" name="panel" />

                <slot v-if="$slots.export" name="export" />

                <div v-if="showSettings" class="relative">
                    <button 
                        class="icon-btn" 
                        @click="toggleTableTools = !toggleTableTools;" 
                        ref="toggleTableToolsBtn"
                        title="Table settings"
                    >
                        <svg-importer icon-name="icons/gear" key="vtable-gear" />
                    </button>
                    
                    <transition name="slide">
                        <div
                            v-if="toggleTableTools"
                            class="absolute left-0 pl-2 pt-2 -mr-1 border rounded arrow-box arrow-box_right dropdown top-12 w__320"
                            style="z-index: 101"
                            v-closable="{
                                exclude: ['toggleTableToolsBtn'],
                                handler: 'onClose'
                            }"
                        >
                            <h5 class="pb-2 pl-2 mr-2 text-gray-700 border-b flex flex__justify-between">
                                <span>Table settings</span>
                                <button class="pr-2" @click="toggleTableTools = !toggleTableTools">
                                    <svg-importer icon-name="icons/close" />
                                </button>
                            </h5>

                            <draggable v-for="(item, i) in groups" :key="item" :group="item" :index="i" />
                        </div>
                    </transition>
                </div>
            </div>

            <div class="flex flex__align-center">
                <span v-if="table.total" class="mr-auto text-body">
                    {{ table.from+1 }}-{{ table.to }} of {{ table.total }}

                    <span class="ml-1" v-if="selected">(selected {{selected}})</span>
                </span>

                <select class="w-48 text-sm form-select ml-4 border-radius--input bg-transparent focus:bg-transparent" v-model="table.pageSize" @change="setPageSize()" :disabled="toggleTableTools">
                    <option :value="10">10 rows</option>
                    <option :value="50">50 rows</option>
                    <option :value="100">100 rows</option>
                    <option :value="200">200 rows</option>
                    <option :value="500">500 rows</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import Draggable from './Draggable.vue'

    export default {
        inject: ["table"],
        props: {
            customExport: {
                type: Function,
                default: null
            },
            toolsRight: {
                type: Boolean,
                default: true
            },
            branding: {
                required: false,
                default: true,
                type: Boolean
            },
            selected: {
                type: Number,
                default: 0,
            },
            columns: {
                type: Array,
            },
            showSettings: {
                type: Boolean,
                default: true,
            }
        },
        data: () => ({
            filterTerm: null,
            toggleTableTools: false,
            pageSize: 50,
        }),
        computed: {
            groups() {
                const result = this.table.toggleableColumns
                    .filter(item => { return item.group })
                    .map(item => { return item.group });

                return [...new Set(result)];
            }
        },
        methods:{
            doExport(){
                if(this.table.customExport){
                    this.table.customExport(this.table);
                } else {
                    this.table.exportData();
                }
            },
            setPageSize(){
                this.table.page = 1;
                this.table.commitState()
            },
            clearSearch() {
                this.table.filter = ''
                this.table.commitState()
            },
            pushSearch() {
                //this.table.setFilter(this.filterTerm)
                this.table.page = 1
                this.table.commitState()
            },
            onClose() {
                this.toggleTableTools = false;
            },
        },
        watch: {
            toggleTableTools(value) {
                this.$emit('toggleTableTools', value);
            },
        },
        components: {
            Draggable,
        },
    };
</script>

<style lang="scss">
input[type="checkbox"][disabled] {
    opacity: 0.5;
}
</style>
