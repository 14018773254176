<template>
    <div>
        <div class="flex pr-2">
            <span class="pt-3 pb-3 pl-2 pr-2 uppercase f-size-14s border__bottom-grey w-full" :class="{'border__top-grey': index > 0}">{{ group }}</span>
        </div>

        <draggable v-model="toggleableColumns" @update="updated" class="dropdown_scroll list-group" handle=".handle">
            <div v-for="(col, cIndex) in toggleableColumns" :key="cIndex" class="mr-2 pl-2 pr-2 border__bottom-grey_not-last">
                <label class="flex flex__justify-between items-center" style="height: 56px" v-if="col.key != 'actions'">
                    <span class="flex items-center cursor-pointer">
                        <input
                            type="checkbox"
                            class="form-checkbox"
                            :checked="col.toggled"
                            :disabled="cIndex < 2 && col.pin"
                            @change="updateCheckbox(col)"
                        />
                        <span class="ml-2">{{ col.title }}</span>
                    </span>

                    <span class="flex items-center">
                        <button v-if="cIndex < 2" @click="updatePin(col, cIndex)" class="btn-pin">
                            <fa :icon="['fal','thumbtack']" style="width: 20px; height: 20px;" :class="{ 'opacity-25': !col.pin }" />
                        </button>

                        <button class="handle ml-5">
                            <fa :icon="['fal','grip-lines']" style="width: 20px; height: 20px;" />
                        </button>
                    </span>
                </label>
            </div>
        </draggable>
    </div>
</template>

<script>
import Draggable from 'vuedraggable'

export default {
    inject: ["table"],

    props: {
        group: {
            type: String,
            default: null,
        },
        index: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            toggleableColumns: [],
            trigger: 0,
        }
    },
    computed: {
        branding() {
            return this.$route.name.indexOf('admin') === -1
        }
    },
    methods: {
        updateTable() {
            const table = [...this.table.columns];

            const groups = Object.values(table.reduce((acc, col) => {
                const group = col.group;

                acc[group] ??= [];
                acc[group].push({ ...col });

                return acc;
            },{}));

            const index = groups.findIndex(item => { return item[0].group === this.group });

            groups[index] = this.toggleableColumns;

            this.table.columns = groups.flat();
        },
        updateCheckbox(col) {
            this.table.loading = true;

            setTimeout(() => { col.toggled = !col.toggled });

            this.table.loading = false;

            this.updateTable();
        },
        save() {
            this.toggleableColumns.forEach((item, index) => {
                item.sort = index;
            })

            this.updateTable();
        },
        updateColumns() {
            const findFirstPin = this.toggleableColumns.findIndex(item => {
                return !item.pin;
            });

            this.toggleableColumns.forEach((item, index) => {
                if (findFirstPin != -1 && index > findFirstPin) {
                    item.pin = false;
                }

                if (index > 2) {
                    item.pin = false;
                }

                item.index = index;
            });

            this.updateTable();
        },
        async updatePin(col, colIndex) {
            col.pin = !col.pin;

            if (col.pin) {
                col.toggled = true;
            }

            if (col.pin) { //true
                this.toggleableColumns.forEach((item, index) => {
                    if (index <= colIndex) {
                        item.pin = true;
                        item.toggled = true;
                    }
                });
            }

            if (!col.pin) {
                this.toggleableColumns.forEach((item, index) => {
                    if (index >= colIndex) {
                        item.pin = false;
                    }
                });
            }

            this.trigger++;
        },
        updated() {
            setTimeout(async () => {
                await this.updateColumns();
            }, 250)
        },

        setup() {
            if (!this.group) {
                console.error('Specify a group');

                return;
            }

            this.toggleableColumns = this.table.toggleableColumns.filter(item => {
                return item.group === this.group;
            });
        }
    },

    created() {
        this.setup();
    },

    mounted() {
        this.updated()
    },

    watch: {
        trigger() {
            this.updated();
        },
        'table.loading'() {
            this.updated();
        },
        'table.trigger'(value) {
            if (value <= 1) {
                this.updated();
            }
        }
    },

    components: {
        Draggable,
    },
}
</script>