<template>
    <div class="w-full">
        <v-debug v-if="debug" />

        <v-panel v-if="panel" :export="customExport"  :toolsRight="panelRight" :branding="branding" :selected="selected" @toggleTableTools="toggleTableTools = $event" :columns="columns" :settings-icons-outline="settingsIconsOutline">
            <template slot="panel">
                <slot name="panel"></slot>
            </template> 

            <template slot="export">
                <slot name="export"></slot>
            </template>

            <template slot="panel_right">
                <slot name="panel_right"></slot>
            </template>
        </v-panel>

        <v-panel-multi v-if="panelMulti" :export="customExport"  :toolsRight="panelRight" :branding="branding" :selected="selected" @toggleTableTools="toggleTableTools = $event" :columns="columns">
            <template slot="panel">
                <slot name="panel"></slot>
            </template>

            <template slot="export">
                <slot name="export"></slot>
            </template>
        </v-panel-multi>

        <v-tools v-if="tools" :export="customExport"  :toolsRight="toolsRight" :branding="branding">
            <template slot="tools">
                <slot name="tools"></slot>
            </template>

            <template slot="after-tools">
                <slot name="after-tools" />
            </template>
        </v-tools>


        <div class="card-shadow overflow-hidden">
            <div class="block app-table table-fixed" :class="{ 'whitespace-no-wrap': textWrappable, 'app-table_loading': table.loading || table.isLoading }">
                <recycle-scroller
                    class="app-table__tbody"
                    :items="table.loading || table.isLoading ? [] : table.visibleRows"
                    :item-size="itemSize"
                    :key-field="keyId"
                    :class="{scroller: (table.loading || table.isLoading) || table.visibleRows.length}"
                >
                    <template #before>
                        <div class="app-table__thead-wr">
                            <div class="app-table__thead">
                                <div class="w-20 p-0 min-w-20 relative text-base align-middle app-table__th text-subheader color-text-secondary app-table__th_checkable" v-if="checkable">
                                    <input
                                        type="checkbox"
                                        class="form-checkbox"
                                        v-model="table.allRowsChecked"
                                        :indeterminate.prop="table.checkedRows.length > 0 && !table.allRowsChecked"
                                        @change="toggleAllRows"
                                    />
                                </div>

                                <div class="w-px p-0 text-base app-table__th min-w-12" v-if="hasDetail"></div>

                                <app-th
                                    v-for="(col, cIndex) in table.toggledColumns"
                                    :key="`${col.title}_${cIndex}`"
                                    :export-key="col.key"
                                    :sort-key="getSortKey(col)"
                                    :class="[col.width, col.thClass, `min-${col.width}`, {'sticky': col.pin, 'z-10': col.pin, 'pl-1': col.pin}]"
                                    class="ok"
                                    :style="pinStyleTh(col, cIndex)"
                                    :title="col.title"
                                >
                                    <div class="truncate">
                                        {{ col.title }}
                                    </div>
                                </app-th>
                            </div>
                        </div>
                    </template>

                    <template v-if="table.loading || table.isLoading" #after>
                    <app-table-loading :checkable="checkable" :cols="table.toggledColumns" :rows="table.pageSize" :hasDetail="hasDetail" />
                    </template>

                    <template v-if="!table.loading && !table.isLoading" v-slot="{ item, index }">
                        <div class="app-table__tr-wr text-body">
                            <div class="app-table__tr" :class="{'cursor-pointer': ! static, 'static': static}" @click="rowClick(item, index);" @dblclick="rowDbClick(item, index);" @mouseover="$emit('row-mouse-over', item)" @mouseleave="$emit('row-mouse-leave', item)">
                                <div class="relative text-base align-middle app-table__td app-table__td_checkable w-18 pr-1 text-body" @click.stop v-if="checkable">
                                    <div v-if="customCheckable" class="saved-row-container">
                                        <div v-if="item.bookmarks.includes(1)" class="saved-row-container__item saved-row--success"></div>
                                        <div v-if="item.bookmarks.includes(2)" class="saved-row-container__item saved-row--error"></div>
                                        <div v-if="item.bookmarks.includes(100)" class="saved-row-container__item saved-row--auto"></div>
                                        <div v-if="item.activeSnooze" class="saved-row-container__item saved-row--orange" title="Snooze"></div>
                                        <div v-if="item.activeReminder" class="saved-row-container__item saved-row--purple" title="Reminder"></div>
                                        <div v-if="item.expiredReminder" class="saved-row-container__item saved-row--cyan" title="Expired reminder"></div>
                                    </div>

                                    <div v-else>
                                        <div v-if="isSavedRow(item)" class="saved-row"></div>
                                        <div v-if="isErrorMarkVisible(item)" class="saved-row saved-row--error"></div>
                                    </div>

                                    <div class="flex flex__align-center row-checkboxes" style="margin-top: 5px;">
                                        <button
                                            v-if="$route.name === 'admin.shipments'"
                                            type="button"
                                            :class="{ seen: getShipmentSeenToday(item.last_seen_by_admin_at)  }"
                                            class="last-seen-indicator mr-3"
                                            @click="getShipmentSeenToday(item.last_seen_by_admin_at) ? $emit('unsee', item.uuid) : false"
                                        />

                                        <input
                                            type="checkbox"
                                            v-model="table.checkedRows"
                                            :value="item[rowKey]"
                                            @click="$emit('check-row', item)"
                                            class="form-checkbox"
                                        />
                                    </div>
                                </div>
                                <div class="pt-0 pb-0 pl-6 align-middle app-table__td w-12 min-w-12" v-if="hasDetail">
                                    <button
                                        class="flex items-center w-12 h-8 -ml-6"
                                        @click="toggleDetail(index)"
                                    >
                                        <fa
                                            :icon="['fal',currentDetail == index ? 'angle-down' : 'angle-right']"
                                            transform="grow-14"
                                            class="mx-auto"
                                        />
                                    </button>
                                </div>
                                <div
                                    v-for="(col, cIndex) in table.toggledColumns"
                                    :key="`${cIndex}_${index}`"
                                    :class="[tdClasses(col, index), tdClassName(table.toggledColumns, cIndex)]"
                                    :title="Array.isArray(item[col.key]) ? null : item[col.key]"
                                    @copy="copy = true"
                                    class="app-table__td text-body"
                                    :style="pinStyleTd(cIndex)"
                                
                                >
                                    <slot name="cell" :row="item" :col="col" :index="index" />
                                </div>
                            </div>
                        </div>
                    </template>
                </recycle-scroller>
            </div>

            <v-pagination v-if="!paginate" class="bg-white" />

            <v-paginate v-else :selected="selected" :toggleTableTools="toggleTableTools" class="bg-white" />

            <slot v-if="table.total !== 0" name="bottom-tools" class="bg-white"></slot>

            <slot v-if="table.total === 0" name="empty" class="bg-white"></slot>
        </div>
    </div>
</template>

<script>
    import table from "./table";
    import AppTh from './AppTh'
    import AppTableLoading from './AppTableLoading'
    import VPanelMulti from './VPanelMulti/index.vue'
    import Vue from "vue";
    import moment from 'moment';

    export default {
        name: "VirtualTable",

        components: {
            AppTh,
            AppTableLoading,
            VPanelMulti,
        },

        props: {
            id: {
                type: String
            },
            branding: {
                required: false,
                default: true,
                type: Boolean
            },
            rows: {
                type: Array,
                default: () => []
            },
            columns: {
                type: Array,
                default: () => []
            },
            queryParams: {
                type: Boolean,
                default: () => false
            },
            rowKey: {
                type: String,
                default: () => "id"
            },
            debug: {
                type: Boolean,
                default: () => false
            },
            endpoint: {
                type: String,
                default: () => null
            },
            exportEndpoint: {
                type: String,
                default: () => null
            },
            checkable: {
                type: Boolean,
                default: () => false
            },
            textWrappable: {
                type: Boolean,
                default: () => true
            },
            tools: {
                type: Boolean,
                default: () => true
            },
            toolsRight: {
                type: Boolean,
                default: () => true
            },
            panel: {
                type: Boolean,
                default: () => false
            },
            panelMulti: {
                type: Boolean,
                default: () => false
            },
            panelRight: {
                type: Boolean,
                default: () => true
            },
            responseProperty: {
                type: String,
                default: () => "data"
            },
            hasDetail: {
                type: Boolean,
                default: () => false
            },
            paginate: {
                type: Boolean,
                default: () => false
            },
            sortKey: {
                type: String,
                default: () => "updated_at"
            },
            sortOrder: {
                type: Number,
                default: () => -1
            },
            loading: {
                type: Boolean,
                default: () => false
            },
            filterParams: {
                type: Object,
                default: () => {}
            },
            noCache: {
                type: Boolean
            },
            onRowsLoaded: {
                type: Function,
                default: () => {}
            },
            onRowsPreloaded: {
                type: Function,
                default: () => {}
            },
            static: {
                type: Boolean,
                default: false,
                required: false,
            },
            savedRows: {
                type: Array,
                default: () => []
            },
            requiredFields: {
                type: Array,
                required: false,
                default: () => []
            },
            selected: {
                type: Number,
                default: 0,
            },
            customExport: {
                type: Function,
                default: null
            },
            exportable: {
                type: Boolean,
                default: true,
            },
            editable: {
                type: Boolean,
                default: false,
            },
            draggable: {
                type: Boolean,
                default: false,
            },
            keyId: {
                type: String,
                default: 'id',
            },
            itemSize: {
                type: Number,
                default: 48,
            },
            savedData: {
                default: null,
            },
            customCheckable: {
                type: Boolean,
                default: false,
            },
            serializeParams: {
                type: Boolean,
                default: false,
            },
            filters: {
                type: Object,
                default: () => { return {} },
            },
            settingsIconsOutline: {
                type: Boolean,
                default: true,
            },
        },

        data: () => ({
            currentDetail: null,
            overflowMargin: 0,
            toggleTableTools: false,
            copy: false,
        }),

        computed: {
            total() {
                return this.table.total
            },
            overflowMarginStyle() {
                return `padding-bottom: ${this.overflowMargin}px; margin-bottom: -${this.overflowMargin}px;`;
            },
        },

        watch: {
            total() {
                this.$emit('total', this.total)
            },
            loading(to, from) {
                this.table.loading = to;
            },
            rows(to, from) {
                this.table.loading = true;
                this.table.isLoading = true;

                this.table.rows = this.rows;

                this.table.loading = false;
                this.table.isLoading = false;
            },
            $route(to, from) {
                if (!to.query['filter[search]']) {
                    this.filterTerm = "";
                } else {
                    this.filterTerm = to.query['filter[search]'];
                }

                if (to.query && to.query.page) {
                    if (!this.table.rows || this.table.rows.length === 0 || this.queryParams) {
                        this.table.fetchRows();
                    }

                    this.table.syncState(to.query);

                    this.$emit('query-updated-again')
                }
            },
            filterParams(to, from) {
                this.table.loading = true;

                this.table.goToPage(1);
                // this.table.filterParams = to;
                this.$set(this.table, 'filterParams', to);
                this.table.commitState();

                this.table.loading = false;
            },
            filters:{
                handler(){
                    this.table.filters = this.filters;
                },
                deep: true
            },
            'table.rows'() {
                this.$emit('table-rows', this.table.rows);
            },
            'table.pageSize'() {
                this.$emit('updated-page-size', this.table.pageSize);
            },
            'table.page'() {
                this.$emit('updated-page', this.table.page);
            },
            'table.sendResponse'() {
                this.$emit('send-response', true)
            },
            'table.columns': {
                handler(to, from){
                    this.$emit('updated-columns', this.table.columns);
                },
                deep: true
            },
            'table.sortKey'() {
                this.$emit('updated-sort-key', this.table.sortKey);
            },
            'table.sortOrder'() {
                this.$emit('updated-sort-order', this.table.sortOrder);
            },
            'table.total'() {
                this.$emit('updated-total', this.table.total);
            }
        },
        async created() {
            this.table.serializeParams = this.serializeParams;

            if (this.paginate)  {
                this.table.pageSize = 50;
            }

            await this.initTable();
            await this.setData();
        },
        methods: {
            getShipmentSeenToday(value) {
                if (value === null && typeof value !== 'string') {
                    return false;
                }

                
                return moment().isSame(value, 'day');
            },
            tdClassName(table, index) {
                const className = table[index];

                if (!className) { 
                    return '' 
                }

                return `${className.width} min-${className.width}`;
            },
            eventClick(event) {
                this.$emit('event-click', event);

                event.stopPropagation()
            },
            setOverflowMargin(value) {
                this.overflowMargin = value;
            },
            getSortKey(col) {
                if (! col.sortable) {
                    return null;
                }

                return col.sortable_key ? col.sortable_key : col.key;
            },
            rowClick(row, index) {
                this.$emit('row-click', { data: row, index: index})
            },
            rowDbClick(row, index) {
                this.$emit('row-dbclick', { data: row, index: index})
            },
            initTable() {
                this.table.id = this.id;
                this.table.query = this.query;
                this.table.rows = this.rows;
                this.table.queryParams = this.queryParams;
                this.table.endpoint = this.endpoint;
                this.table.exportEndpoint = this.exportEndpoint;
                this.table.checkable = this.checkable;
                this.table.responseProperty = this.responseProperty;
                this.table.hasDetail = this.hasDetail;
                this.table.sortKey = this.sortKey;
                this.table.sortOrder = parseInt(this.sortOrder);
                this.table.loading = this.loading;
                this.table.filterParams = this.filterParams;
                this.table.noCache = this.noCache;
                this.table.onRowsLoaded = this.onRowsLoaded;
                this.table.onRowsPreloaded = this.onRowsPreloaded;
                this.table.customExport = this.customExport;

                if (this.savedData?.table_settings?.length) {
                    this.table.columns = this.savedData.table_settings;
                    this.table.pageSize = +this.savedData.count_rows;
                    this.table.page = +this.savedData.pagination;

                    return;
                }

                if (this.filters) {
                    this.table.filters = this.filters;
                }

                this.table.setColumns(this.columns);
            },
            setData() {
                if (this.queryParams) {
                    this.table.router = this.$router;
                    this.table.route = this.$route;
                }

                this.table.rows = this.rows;

                if (this.queryParams && !this.endpoint) {
                    /*
                            If we want to base the table on the queryParams, and a page is set in the url
                        */
                    // Query params and page set
                    this.table.syncState(this.$route.query);
                    this.$emit('query-updated')

                    return;
                }

                if (this.queryParams && this.endpoint) {
                    /*
                            If we DO want to base the table on the queryParams, but use internal fethcing for rows
                        */
                    // query params with endpoint
                    this.table.commitState();

                    return;
                }

                if (!this.queryParams && this.endpoint) {
                    /*
                            If we DON'T want to base the table on the queryParams, but use internal fethcing for rows
                        */
                    // No query params but with endpoint
                    this.table.fetchRows();

                    return;
                }

                // None of the above
                this.table.commitState();
            },
            toggleAllRows() {
                if (!this.table.allRowsChecked) {
                    this.table.checkedRows = [];

                    this.$emit('check-row', 'uncheckAllRows')
                } else {
                    this.table.checkedRows = this.table.visibleRows.map(
                        row => row[this.rowKey]
                    );

                    this.$emit('check-row', 'checkAllRows')
                }
            },
            checkedRows() {
                return this.table.checkedRows
            },
            getRows() {
                return this.table.rows
            },
            refreshRows() {
                return this.table.commitState();
            },
            toggleDetail(rowIndex) {
                if (this.currentDetail == rowIndex) {
                    this.currentDetail = null;
                } else {
                    this.currentDetail = rowIndex;
                }
            },
            isSavedRow(row) {
                if (this.savedRows != null) {
                    if(this.savedRows.includes(row.consignment_no)) {
                        return true;
                    }
                }
            },
            isErrorMarkVisible(row) {
                for (let i = 0; i < this.requiredFields.length; ++i) {
                    const fieldName = this.requiredFields[i];
                    if (!row[fieldName]) {
                        return true;
                    }
                }
                return false;
            },
            tdClasses(col, index) {
                return [
                    {
                        'text-right pr-6': col.alignRight,
                        'overflow-visible-important': col.title === 'Sites' || col.key === 'consignments' || col.overflow,
                        'the-last': index > this.table.visibleRows.length - 5,
                        'sticky': col.pin,
                        'p-1': col.pin,
                        'truncate': col.truncate === undefined || col.truncate === true,
                        'align-middle': true
                    },
                    col.tdClass,
                ];
            },
            pinComputed(value, index) {
                if (!value.pin) {
                    return '';
                }

                if (index > 2) {
                    return '';
                }

                if (index === 0) {
                    return { left: `0px` }
                }

                if (index === 1) {
                    return { left: `${+this.table.toggledColumns[0].width.slice(2) / 4}rem` }
                }

                if (index === 2) {
                    const first = +`${+this.table.toggledColumns[0].width.slice(2) / 4}`;
                    const prev =  +`${+this.table.toggledColumns[1].width.slice(2) / 4}`;

                    return { left: `${first + prev}rem` }
                }
            },
            pinStyleTh(value, index) {
                return this.pinComputed(value, index);
            },
            pinStyleTd(index) {
                const value = this.table.toggledColumns[index];

                return this.pinComputed(value, index);
            }
        },
        beforeCreate() {
            this.table = new Vue(table);
        },
        provide() {
            return {
                table: this.table
            };
        },
    };
</script>

<style lang="scss">
    @keyframes placeHolderShimmer {
        0% {
            background-position: -468px 0;
        }
        100% {
            background-position: 468px 0;
        }
    }
    .linear-background {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #f6f7f8;
        background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.05) 8%,
            rgba(0, 0, 0, 0.07) 18%,
            rgba(0, 0, 0, 0.05) 33%
        );
        background-size: 1000px 104px;
        overflow: hidden;
    }

    .saved-row {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background-color: gray;
    }

    .table-tools {
        @apply py-3 px-4 flex items-center;

        &.search {
            @apply pl-0;
        }

    .table-search {
        @apply flex items-center flex-grow;
    }
}

@keyframes ltable {
    0% {
        left: 0;
        width: 0%;
    }

    50% {
        left: 0;
        width: 100%;
    }

    100% {
        left: 100%;
        width: 0%;
    }
}

.app-table {
  @apply w-full;

//   &:before {
//       position: absolute;
//       border-bottom-width: 1px;
//       width: 100%;
//       height: 0px;
//       top: 41px;
//       left: 0;
//       content: '';
//       z-index: 12;
//   }

//   .vue-recycle-scroller__item-wrapper {
//       overflow: visible;
//   }

    &_loading {
        .vue-recycle-scroller__item-wrapper {
            display: none
        }
    }

    overflow-x: auto;
    overflow-y: hidden;

    position: relative;

    & + div {
        border-top-width: 1px;
    }

  &__thead-wr {
      width: 100%;
      display: flex;
    //   overflow: hidden;
  }

  &__thead {
    //   min-width: 100%;
      flex: 1;
      display: flex;
      justify-content: flex-start;
      border-bottom-width: 1px;
    //   overflow-x: auto;
  }

  &__tbody {
    @apply bg-white;
  }

  &__tr {
      display: flex;
      justify-content: flex-start;

      position: relative;

      &_loading {
            &:hover {
                background: transparent !important;
            }
      }
  }

  &__tr-wr {
      width: 100%;

      &_loading {
          border-bottom-width: 1px;

            &:last-child {
                border: none;
            }
      }
  }

  /*tr:nth-child(odd) {
    @apply bg-smoke-100;
  }*/

  &__tr.vt-selected, &__tr:hover {
    @apply bg-gray-200;
  }

  &__tr.static.vt-selected, &__tr.static:hover {
    @apply bg-white;
  }

  &__th {
    @apply py-3 bg-white text-left align-text-top relative;

    display: flex;
    align-items: center;
    padding-right: 16px;

    &.bg-gray-200 {
        background-color: #F6F6F6 !important;
    }

    &_checkable {
        margin-right: 0 !important;
        padding-right: 10px !important;
        text-align: right;
    }

    &:not(:last-child) {
        margin-right: 24px;

        &::before {
            content: "";
            display: block;
            width: 1px;
            height: 22px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background: var(--color-border);
        }

        &::after {
            content: "";
            display: block;
            width: 24px;
            height: 100%;
            position: absolute;
            right: -24px;
            top: 0;
            background: #fff;
        }
    }

    svg {
      @apply inline-block;
    }

    &:first-child {
        margin-left: 0;
    }

    &_checkable {
        &::before {
            content: none;
        }
    }
  }

  &__td:first-child, &__th:first-child {
    @apply pl-8;
  }

  &__td:last-child, &__th:last-child {
    @apply pr-6;
  }

  &__td {
    @apply py-3 align-top;

    margin-right: 24px;

    &_checkable {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    &.actions {
        position: relative;
        margin-left: auto;
    }

    &:last-child {
        margin-right: 0 !important;
    }

    svg, img, span {
      @apply align-middle;
    }
  }

  .vue-recycle-scroller__item-view {
        width: 100%;
        border-bottom-width: 1px;

        &:last-child {
            border: none;
        }
    }
}

.table-sm {
  @apply text-xs;

  &_-td, .app-table__th {
    @apply py-2 text-xs;
  }

  .table-tools {
    @apply py-1 px-3;

    &.search {
        @apply pl-0;
    }
  }

  .table-search {
    @apply text-xs;
  }
}

.reseller-table {
  .app-table__td:first-child, .app-table__th:first-child {
    @apply pl-0;
  }

  .app-table__tr:hover {
    @apply bg-white;
  }

  .app-table__td {
    @apply border-0;
  }
}

.overflow-visible-important {
    overflow: visible !important;
}

.sites {
    position: relative;

    &:hover {
        .sites-card {
            display: inline-block !important;
            right: -25px;
            transition: 0.2s all ease-in-out;

            &:after, &:before {
                bottom: 100%;
                left: 70%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;

                border-color: rgba(213, 213, 213, 0);
                border-bottom-color: #fff;
                border-width: 15px;
                margin-left: -15px;
            }

            &:before {
                border-color: rgba(245, 2, 2, 0);
                border-bottom-color: #E6E6E6;
                border-width: 16px;
                margin-left: -16px;
            }
        }
    }
}

.address-table {
    .app-table__td {
        max-width: 103px;
        white-space: nowrap;
        text-overflow: ellipsis;
        @apply overflow-hidden pr-2;
    }

    .overflow-truncate-long {
        max-width: 206px;
    }
}

.a-content {
    height: 200px;
    // overflow-y: auto;
    position: relative;
}

.vue-recycle-scroller__item-wrapper {
    overflow: initial;
}

.a-wrapper {
    display: block;
}


.vue-recycle-scroller__item-view {
    width: auto !important;
    min-width: 100%;
}

.vue-recycle-scroller__slot {
    z-index: 11;
}

.vue-recycle-scroller__slot:first-child {
    position: sticky;
    top: 0;
    background: white;
}

.saved-row-container {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    &__item {
        height: 100%;
        width: 4px;
        margin-right: 1px;
    }
}
</style>

<style lang="scss" scoped>
.row-checkboxes {
    margin-top: 5px;
}

.last-seen-indicator {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 20px;
    border: 1px solid var(--brand-color);
    background: var(--brand-color);
    outline: 0;

    &.seen {
        background: transparent;
    }
}
</style>